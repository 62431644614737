<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Case Severity
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-thermometer-alert" prominent type="info">
          <div>
            <h3>Severities add a crucial layer to UnStruct's OCO categorization, providing configurability:</h3>
            <ul>
              <li>
                <strong>Impact Assessment:</strong> Severity measures the impact an IAO or issue has on the system, product, or service.
              </li>
              <li>
                <strong>Consideration Factors:</strong> This assessment can consider various factors, such as the extent of damage, the number of users affected, or the potential harm to the business.
              </li>
              <li>
                <strong>Understanding Magnitude:</strong> Severity levels assist in grasping the magnitude of the problem, aiding in prioritization but not necessarily dictating the order of resolution.
              </li>
            </ul>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field v-model="q" append-icon="search" label="Search" single-line hide-details clearable />
          </v-card-title>
          <v-row>
          <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
            <v-card class="ma-4 elevation-2">
              <!-- Item Name and Status -->
              <v-card-title class="justify-space-between">
                <div>
                  <v-icon large left>mdi-thermometer-alert</v-icon>
                  {{ item.name }}
                </div>
                <div>
                  <v-chip v-if="item.default" color="primary" dark class="mr-2">
                    <v-icon left>mdi-star</v-icon>
                    Default
                  </v-chip>
                  <v-chip v-if="item.enabled" color="success" dark class="mr-2">
                    <v-icon left>mdi-lightbulb-on-outline</v-icon>
                    Enabled
                  </v-chip>
                  <v-chip v-if="!item.enabled" dark class="mr-2">
                    <v-icon left>mdi-lightbulb-off-outline</v-icon>
                    Disabled
                  </v-chip>
                </div>
              </v-card-title>

              <!-- Item Description -->
              <v-card-text>
                <div>
                  <v-icon left>mdi-axis-arrow-info</v-icon>
                  Description: {{ item.description }}
                </div>
                <div>
                  <v-icon left>mdi-sort-numeric-ascending</v-icon>
                  View Order: {{ item.view_order }}
                </div>
              </v-card-text>

              <!-- Actions -->
              <v-card-actions class="justify-end">
                <v-btn @click="createEditShow(item)" text color="primary">View / Edit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import NewEditSheet from "@/case/severity/NewEditSheet.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"

export default {
  name: "CaseSeverityTable",

  components: {
    NewEditSheet,
    SettingsBreadcrumbs,
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Description", value: "description", sortable: false },
        { text: "Default", value: "default", sortable: true },
        { text: "Enabled", value: "enabled", sortable: true },
        { text: "View Order", value: "view_order", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("case_severity", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query", "params"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("case_severity", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
